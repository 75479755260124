import type { ValueOf, ObjectPathsDeep } from '../internal/utils'
import type { Person, PersonRef } from './person'
import type { Specialization, SpecializationRef } from './specialization'
import type { Study, StudyRef } from './study'
import type { Topic, TopicRef } from './topic'
import type { Grade, Page, PersonName } from '../common'

/**
 * Main states of the Thesis.
 *
 * TODO: Add link to the documentation of the state machine.
 */
export const ThesisMainState = {
  Draft: 'draft',
  Proposed: 'proposed',
  Assigned: 'assigned',
  Review: 'review',
  ReadyForDefence: 'readyForDefence',
  Evaluated: 'evaluated',
  Archived: 'archived',
  Deleted: 'deleted',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisMainState = ValueOf<typeof ThesisMainState>

export const StateMap = {
  draft: {},
  proposed: {
    awaitsSpecOfficerApproval: {},
    revision: {},
    awaitsAssigneeAcceptance: {},
    rejectedByAssignee: {},
    acceptedByAssignee: {},
  },
  changeable: {
    assigned: {
      reviewer: {
        pending: {},
        assigned: {},
      },
    },
    review: {
      supervisor: {
        pending: {},
        submitted: {},
      },
      reviewer: {
        pending: {},
        submitted: {},
      },
    },
    reviewed: {},
  },
  readyForDefence: {},
  evaluated: {},
  archived: {},
  deleted: {},
} as const

/**
 * A string union of all fully-qualified Thesis states (paths in dot-notation).
 *
 * @hidden JSON Schema generator cannot process this type
 */
export type ThesisState = ObjectPathsDeep<typeof StateMap>

// TODO
export const LicenseType = {
  Open: 'open',
  Restricted: 'restricted',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LicenseType = ValueOf<typeof LicenseType>

// NOTE: This extends the Language type, but JSON Schema generator cannot
// handle `{ ...Language, Sk: 'sk' }`.
export const ThesisLanguage = {
  Cs: 'cs',
  En: 'en',
  Sk: 'sk',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisLanguage = ValueOf<typeof ThesisLanguage>

export type ThesisLanguageList = Array<ThesisLanguage>

/**
 * Actions that can be invoked on a Thesis.
 * CREATE, READ*, LIST and UPDATE are CRUD actions mapped to HTTP methods (POST, GET, GET, PATCH),
 * all others are state actions.
 */
export const ThesisAction = {
  CREATE: 'CREATE',
  READ: 'READ',
  READ_FILES: 'READ_FILES',
  LIST: 'LIST',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ARCHIVE: 'ARCHIVE',
  ASSIGN_DIRECTLY: 'ASSIGN_DIRECTLY',
  PROPOSE: 'PROPOSE',
  WITHDRAW: 'WITHDRAW',
  REVISE: 'REVISE',
  OFFICER_APPROVE: 'OFFICER_APPROVE',
  ASSIGNEE_ACCEPT: 'ASSIGNEE_ACCEPT',
  ASSIGNEE_REJECT: 'ASSIGNEE_REJECT',
  ASSIGN_REVIEWER: 'ASSIGN_REVIEWER',
  SUPERSEDE: 'SUPERSEDE',
  REQUEST_APPROVAL: 'REQUEST_APPROVAL',
  APPROVE_PRINTS: 'APPROVE_PRINTS',
  SUBMIT_REVIEWER_REPORT: 'SUBMIT_REVIEWER_REPORT',
  SUBMIT_SUPERVISOR_REPORT: 'SUBMIT_SUPERVISOR_REPORT',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisAction = ValueOf<typeof ThesisAction>

/**
 * Thesis Assignment is a part of the Thesis type. It describes the goal of the thesis and who will
 * work on it. This part is fixed once the thesis is approved by the school and the assignee.
 */
export interface ThesisAssignment {
  /**
   * Czech title.
   * @minLength 5
   */
  titleCs: string

  /**
   * English title.
   * @minLength 5
   */
  titleEn: string

  /**
   * Text of the Thesis Assignment with instructions and requirements for the student written in
   * the language specified by `language`.
   * @default ""
   * @maxLength 3400
   */
  // NOTE: KOS limits the length to 4000 bytes (not characters!).
  description: string

  /**
   * Language in which the Thesis will be written.
   * @default "cs"
   */
  language: ThesisLanguage

  /**
   * TODO
   * @default "open"
   */
  licenseType: LicenseType | null

  /**
   * The student (more precisely, his or her study) assigned to work on this Thesis Assignment.
   * It can be undefined in the *draft* state only.
   */
  assignee?: Required<StudyRef> | Study | null

  /**
   * The study Specialization for which this Thesis Assignment is intended.
   * This property is automatically set to the Assignee's Study Specialization when entering the
   * *proposed* state; it's not set in the *draft* state.
   */
  specialization?: SpecializationRef | Specialization | null

  /**
   * Thesis topic this thesis (assignment) is based on.
   * @default null
   */
  topic?: TopicRef | Topic | null
}

export const AttachmentType = {
  Dataset: 'dataset',
  Demo: 'demo',
  Paper: 'paper',
  SourceCode: 'sourceCode',
  Website: 'website',
  Other: 'other',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AttachmentType = ValueOf<typeof AttachmentType>

type AttachmentCommon = {
  /**
   * Attachment URL
   */
  href: string,
  /**
   * Attachment type
   */
  type: AttachmentType,
  /**
   * Attachment link Czech text (mandatory when attachment type is 'other')
   */
  titleCs: string,
  /**
   * Attachment link English text (mandatory when attachment type is 'other')
   */
  titleEn: string,
}

export type LinkAttachment = AttachmentCommon

export type FileAttachment = AttachmentCommon & {
  /**
   * Attachment file name
   */
  filename: string,
  /**
   * Attachment file current size
   */
  currentSize: number,
  /**
   * Attachment file final size
   */
  totalSize: number,
}

export type Attachment = FileAttachment | LinkAttachment

export const AttachmentKey = {
  Href: 'href',
  Type: 'type',
  TitleCs: 'titleCs',
  TitleEn: 'titleEn',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AttachmentKey = ValueOf<typeof AttachmentKey>

/**
 * @maxLength 100
 */
 type Keyword = string

/**
 * Thesis is the main object for this project which represents a final thesis through all states
 * of its lifecycle: from draft, through its approval and submission, up to its eventual evaluation
 * and defence.
 *
 * TODO: Add link to the documentation of the Thesis lifecycle.
 */
export interface Thesis extends ThesisAssignment {

  /** Unique ID. */
  readonly id: number

  /**
   * Czech abstract of the thesis text.
   * @maxLength 3400
   */
  abstractCs?: string | null

  /**
   * English abstract of the thesis text.
   * @maxLength 3400
   */
  abstractEn?: string | null

  /**
   * Czech keywords for the thesis.
   * @maxItems 20
   */
  keywordsCs?: Keyword[] | null

  /**
   * English keywords for the thesis.
   * @maxItems 20
   */
  keywordsEn?: Keyword[] | null

  /**
   * URLs to Thesis files (final text PDF, attachments etc.)
   */
  files: { finalText?: string | null }

  /**
   * URLs to thesis attachments
   */
  attachments: Attachment[]

  /**
   * URL to Thesis' DSpace page
   */
  dspaceUrl?: string | null

  /**
   * If this thesis has been assigned in KOS (using a POST request to KOSapi to resource `/theses`),
   * then this property is the ID which KOS assigned to this thesis.
   */
  kosId?: number | null

  /**
   * This flag indicates if the supervisor would like and will propose the reviewer, so the
   * Specialization Officer doesn't have to look for them.
   *
   * @default false
   */
  supervisorWillProposeReviewer: boolean

  /**
   * Name of the `assignee` as it was at the time when the Thesis was assigned.
   * This property is set automatically; it is undefined only when the `assignee` is undefined.
   */
  assigneeFrozenName?: PersonName | null

  /** The current main state of the Thesis. */
  mainState: ThesisMainState

  /**
   * All states of the Thesis state machine.
   *
   * TODO: Add link to the documentation of the state machine.
   *
   * @uniqueItems true
   */
  states: string[]

  /**
   * Semester code (e.g. B202 for summer semester of academic year 2020/21) after which the thesis
   * is invalidated.
   *
   * @pattern ^[A-Z][0-9][0-9][12]$
   * @examples ["B201", "B202", "B211"]
   */
  validUntil?: string | null

  /** Whether the Thesis was verified by the FT Officer. */
  verifiedByFtOfficer: boolean

  /** The Supervisor of the Thesis. It's typically the same person who has created it. */
  supervisor: PersonRef | Person

  /**
   * Name of the `supervisor` as it was at the time when the Thesis was assigned.
   * This property is set automatically.
   */
  supervisorFrozenName: PersonName

  /**
   * The reviewer assigned to the Thesis.
   * This is generally set by the system when a Reviewer Proposal is accepted and approved.
   */
  reviewer?: PersonRef | Person | null

  /**
   * Name of the `reviewer` as it was at the time when the Thesis entered the *review* state.
   * This property is set automatically; it is undefined only when the `reviewer` is undefined.
   */
  reviewerFrozenName?: PersonName | null

  /**
   * The final evaluation of the thesis (as A-F grade) from the defence.
   */
  finalGrade?: Grade | null

  /**
   * The year of this thesis evaluation/defense
   */
  evaluationYear?: number | null

  /**
   * The thesis that supersedes this thesis. This can be only defined in state `archived`
   * or `evaluated`.
   */
  // NOTE: We cannot use ThesisRef here due circular reference.
  supersededBy?: { id: number } | null

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date

  /**
   * The date and time at which the thesis was approved by the Specialization Officer.
   */
  readonly approvedAt?: Date | null

  /**
   * A map of actions allowed to be performed in the current state of the Thesis by
   * the authorized user.
   */
  readonly _actions?: {
    [K in ThesisAction]?: {}
  }
}

/**
 * A Thesis to be created.
 */
export type ThesisNew =
  & Pick<Thesis, 'titleCs' | 'titleEn'>
  & Partial<Pick<Thesis, 'description' | 'language' | 'licenseType' | 'supervisorWillProposeReviewer' | 'topic'>>
  & {
    /**
     * Student assigned to this Thesis assignment.
     * @additionalProperties true
     */
    assignee?: StudyRef | null,

    /** The Supervisor of the Thesis. Defaults to the current user. */
    supervisor?: PersonRef,
  }

/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a Thesis.
 * This is used for updating an existing Thesis using the PATCH method; merge patch contains only
 * the properties that should be modified.
 */
export type ThesisPatch =
  // eslint-disable-next-line max-len
  & Partial<Omit<Thesis, 'id' | 'mainState' | 'states' | 'modifiedAt' | 'assignee' | 'supervisor' | 'reviewer'
  | 'approvedAt' | 'evaluationYear' | '_actions'>>
  & Pick<ThesisNew, 'assignee' | 'supervisor'>
  & {
    /** The reviewer assigned to the Thesis. */
    reviewer?: PersonRef | null,
  }

/**
 * Metadata of the Theses resource.
 */
export type ThesesMeta = {
  properties: {
    specialization: {
      /** @uniqueItems true */
      values: ReadonlyArray<string | null>,
    },
    validUntil: {
      /** @uniqueItems true */
      values: ReadonlyArray<string | null>,
    },
  },
}

/**
 * Collection of Thesis objects.
 */
export type ThesisList = Page<Thesis>

/**
 * Reference to a Thesis resource (used in request bodies).
 */
export type ThesisRef = Pick<Thesis, 'id'>

export function isThesis (thesis: Thesis | ThesisRef): thesis is Thesis {
  return 'supervisor' in thesis
}
