const thesesPrefix = '/theses'
const topicsPrefix = '/topics'
const settingsPrefix = '/preferences'

export default {
  configuration: '/configuration',
  home: '/',
  theses: {
    root: `${thesesPrefix}`,
    // Returns format string if no id is supplied.
    view: (id?: number): string => `${thesesPrefix}/${id ?? ':thesisId'}`,
    // Returns format string if no id is supplied.
    edit: (id?: number): string => `${thesesPrefix}/${id ?? ':thesisId'}/edit`,
    reviewerProposals: (id?: number): string => `${thesesPrefix}/${id ?? ':thesisId'}/reviewer-proposals`,
    reportView: (id?: number, reportType?: string): string =>
      `${thesesPrefix}/${id ?? ':thesisId'}/${reportType ?? ':reportType'}-report`,
    reportCreate: (id?: number, reportType?: string): string =>
      `${thesesPrefix}/${id ?? ':thesisId'}/${reportType ?? ':reportType'}-report/create`,
    supersede: (id?: number): string => `${thesesPrefix}/${id ?? ':thesisId'}/supersession`,
    create: `${thesesPrefix}/create`,
    search: `${thesesPrefix}/search`,
    searchOfficer: `${thesesPrefix}/search/officer`,
    my: `${thesesPrefix}/my`,
  },
  topics: {
    root: topicsPrefix,
    create: `${topicsPrefix}/create`,
    // Returns format string if no id is supplied.
    view: (id?: number): string => `${topicsPrefix}/${id ?? ':topicId'}`,
    // Returns format string if no id is supplied.
    edit: (id?: number): string => `${topicsPrefix}/${id ?? ':topicId'}/edit`,
    // Returns format string if no id is supplied.
    createThesis: (id?: number): string => `${topicsPrefix}/${id ?? ':topicId'}/create-thesis`,
    my: `${topicsPrefix}/my`,
    search: `${topicsPrefix}/search`,
  },
  auth: {
    loginRedirect: '/login-redirect',
    logout: '/',
  },
  profile: '/profile',
  userSettings: {
    root: settingsPrefix,
    notifications: `${settingsPrefix}#notifications`,
  },
}
