import { NotificationSubtype } from '@cvut/profit-api-types/lib/theses'


export const subject: Record<NotificationSubtype, string> = {
  awaitsReviewer: 'Návrh oponenta',
  officerApproval: 'Schválení zadání',
  officerApprove: 'Schválení oponentury',
  reviewerAccept: 'Přijetí oponentury',
  reviewerReport: 'Odevzdání posudku oponenta',
  revision: 'Revize zadání',
  studentAccept: 'Přijetí zadání',
  studentRejected: 'Odmítnutí zadání studentem',
  submission: 'Odevzdání práce',
  supervisorReport: 'Odevzdání posudku vedoucího',
  acceptedByAssignee: 'Závěrečná práce přijata studentem',
  acceptedByReviewer: 'Oponentura závěrečné práce přijata oponentem/kou',
  approvedByOfficer: 'Zadání závěrečné práce schváleno referentem/kou',
  archived: 'Závěrečná práce archivována',
  assignReviewer: 'Přidělení oponenta/ky závěrečné práce',
  cancelledByProponent: 'Návrh oponentury závěrečné práce stažen navrhovatelem',
  cancelledDueToAcceptanceOfAnother: 'Návrh oponentury závěrečné práce zrušen',
  cancellationRequest: 'Žádost o odzadání závěrečné práce',
  cancellationRequestApproved: 'Žádost o změnu závěrečné práce schválena',
  cancellationRequestRejected: 'Žádost o odzadání závěrečné práce zamítnuta',
  changeRequest: 'Žádost o změnu v zadání závěrečné práce',
  changeRequestApproved: 'Žádost o změnu závěrečné práce schválena',
  changeRequestRejected: 'Žádost o změnu závěrečné práce zamítnuta',
  deleted: 'Závěrečná práce smazána',
  evaluated: 'Závěrečná práce ohodnocena komisí',
  printsApproved: 'Výtisky závěrečné práce byly schváleny',
  proposed: 'Navržení na oponenturu závěrečné práce',
  proposedForApproval: 'Zadání závěrečné práce navrženo ke schválení',
  rejectedByAssignee: 'Závěrečná práce odmítnuta studentem',
  rejectedByOfficer: 'Oponentura závěrečné práce zamítnuta referentem/kou specializace',
  rejectedByReviewer: 'Oponentura závěrečné práce zamítnuta oponentem/kou',
  requestForRemovingAssignee: 'Žádost o odzadání závěrečné práce',
  requestForRemovingAssigneeApproved: 'Žádost o změnu závěrečné práce schválena',
  requestForRemovingAssigneeRejected: 'Žádost o odzadání závěrečné práce zamítnuta',
  resubmitted: 'Závěrečná práce znovu odevzdána',
  returnedForRevision: 'Zadání závěrečné práce vráceno k revizi',
  submitReviewerReport: 'Oponent/ka závěrečné práce označil/a posudek za hotový',
  submitSupervisorReport: 'Vedoucí závěrečné práce označil/a posudek za hotový',
  submitted: 'Závěrečná práce odevzdána',
  updatedByFtOfficer: 'Zadání závěrečné práce upraveno referentem/kou SZZ',
  withdraw: 'Zadání závěrečné práce staženo k přepracování',
}
