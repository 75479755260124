import { NotificationSubtype } from '@cvut/profit-api-types/lib/theses'


export const subject: Record<NotificationSubtype, string> = {
  awaitsReviewer: 'Reviewer proposal',
  officerApproval: 'Thesis assignment approval',
  officerApprove: 'Approval of reviewer proposal',
  reviewerAccept: 'Acceptance of reviewer proposal',
  reviewerReport: 'Reviewer report submission',
  revision: 'Thesis assignment revision',
  studentAccept: 'Thesis assignment acceptance',
  studentRejected: 'Thesis assignment rejection',
  submission: 'Thesis submission',
  supervisorReport: 'Supervisor report submission',
  acceptedByAssignee: 'Thesis has been accepted by the student',
  acceptedByReviewer: 'Reviewer proposal has been accepted by the reviewer',
  approvedByOfficer: 'Thesis assignment has been approved by a specialization officer',
  archived: 'Thesis has been archived',
  assignReviewer: 'Reviewer has been assigned',
  cancelledByProponent: 'Reviewer proposal has been cancelled by the proponent',
  cancelledDueToAcceptanceOfAnother: 'Reviewer proposal has been cancelled',
  cancellationRequest: 'Request for thesis cancellation',
  cancellationRequestApproved: 'Request for thesis cancellation has been approved',
  cancellationRequestRejected: 'Request for removing an assignee from a thesis has been rejected',
  changeRequest: 'Request for a change in thesis assignment',
  changeRequestApproved: 'Request for a change in thesis has been approved',
  changeRequestRejected: 'Request for a change in thesis has been rejected',
  deleted: 'Thesis has been deleted',
  evaluated: 'Thesis has been evaluated',
  printsApproved: 'Thesis prints have been approved',
  proposed: 'Thesis reviewer has been proposed',
  proposedForApproval: 'Thesis assignment has been proposed for approval',
  rejectedByAssignee: 'Thesis has been rejected by a student',
  rejectedByOfficer: 'Reviewer proposal has been rejected by the specialization officer',
  rejectedByReviewer: 'Reviewer proposal has been rejected by the reviewer',
  requestForRemovingAssignee: 'Request for removing an assignee from a thesis',
  requestForRemovingAssigneeApproved: 'Request for removing an assignee from a thesis has been approved',
  requestForRemovingAssigneeRejected: 'Request for removing an assignee from a thesis has been rejected',
  resubmitted: 'Thesis has been resubmitted',
  returnedForRevision: 'Thesis assignment has been returned for revision',
  submitReviewerReport: 'Reviewer\'s report has been marked as completed',
  submitSupervisorReport: 'Supervisor\'s report has been marked as completed',
  submitted: 'Thesis has been submitted',
  updatedByFtOfficer: 'Thesis assignment has been updated by the Final Theses Officer',
  withdraw: 'Thesis assignment has been withdrawn for rework',
}
