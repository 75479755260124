import type { ReactNode } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { CollapseNavigationContainer } from './hooks/useCollapseNavigation'
import { CurrentLanguageContainer } from './locale'
import CollapseNavigation from './components/CollapseNavigation'
import ConfigurationEditPage from './pages/configuration/ConfigurationEditPage'
import Homepage from './pages/Homepage'
import LoginRedirectPage from './pages/LoginRedirect'
import ModalContainer from './features/modals/ModalContainer'
import { ModalsContainer } from './features/modals'
import NavLinks from './components/SiteHeader/NavLinks'
import NotFound from './pages/NotFound'
import OfficerThesesListPage from './pages/theses/OfficerThesesListPage'
import pagePaths from './pages/paths'
import PrivateRoute from './pages/utils/PrivateRoute'
import SiteHeader from './components/SiteHeader'
import ThesisCreatePage from './pages/theses/ThesisCreatePage'
import ThesisEditPage from './pages/theses/ThesisEditPage'
import ThesesListPage from './pages/theses/ThesesListPage'
import ThesisReportCreatePage from './pages/theses/ThesisReportCreatePage'
import ThesisReportViewPage from './pages/theses/ThesisReportViewPage'
import ThesisReviewerPage from './pages/theses/ThesisReviewerPage'
import ThesisViewPage from './pages/theses/ThesisViewPage'
import TopicCreatePage from './pages/topics/TopicCreatePage'
import TopicEditPage from './pages/topics/TopicEditPage'
import TopicSearchPage from './pages/topics/TopicSearchPage'
import TopicViewPage from './pages/topics/TopicViewPage'
import ToastNotifications from './features/toastNotifications'
import TopicThesisCreatePage from './pages/topics/TopicThesisCreatePage'
import UserSettingsPage from './pages/UserSettingsPage'
import { UserInfoContainer } from './session/user-info'
import * as style from './App.style'


// FIXME: These components need keys to assure they rerender when switching a page. Because of internal workings of
// react-router the same component renders without a key to distinguish them.
const thesesList: Record<string, ReactNode> = {
  [pagePaths.theses.my]: <ThesesListPage myThesesOnly key={pagePaths.theses.my} />,
  [pagePaths.theses.search]: <ThesesListPage key={pagePaths.theses.search} />,
}

const topicSearchPages: Record<string, ReactNode> = {
  [pagePaths.topics.search]: <TopicSearchPage key={pagePaths.topics.search} />,
  [pagePaths.topics.my]: <TopicSearchPage myTopics key={pagePaths.topics.my} />,
}

// Per `https://reactrouter.com/web/api/BrowserRouter/basename-string`, a proper
// basename should not end with a trailing slash. Thus if `process.env.PUBLIC_PATH` is
// just `/`, then `undefined` is returned, otherwise trailing slashes are stripped.
function computeRouterBasename () {
  const publicPath = process.env.PUBLIC_PATH
  if (publicPath == null || publicPath === '/') {
    return undefined
  }

  return publicPath.replace(/\/+$/, '')
}

const App = (): JSX.Element => (
  <Router basename={computeRouterBasename()}>
    <UserInfoContainer.Provider>
      <CurrentLanguageContainer.Provider>
        <ModalsContainer.Provider>
          <ModalContainer>
            <ToastNotifications />
            <CollapseNavigationContainer.Provider>
              <SiteHeader
                profilePath={pagePaths.profile}
                userSettingsPath={pagePaths.userSettings.root}
                logoutPath={pagePaths.auth.logout}
              />
              <CollapseNavigation>
                <NavLinks
                  homePath={pagePaths.home}
                  configurationPath={pagePaths.configuration}
                  createTopicPath={pagePaths.topics.create}
                  myTopicsPath={pagePaths.topics.my}
                  searchTopicsPath={pagePaths.topics.search}
                  createThesisPath={pagePaths.theses.create}
                  myThesesPath={pagePaths.theses.my}
                  searchThesesPath={pagePaths.theses.search}
                  searchThesesOfficerPath={pagePaths.theses.searchOfficer}
                />
              </CollapseNavigation>
              <main className={style.main}>
                <section>
                  <Switch>
                    <Route exact path={pagePaths.auth.loginRedirect}>
                      <LoginRedirectPage />
                    </Route>
                    <PrivateRoute exact path={pagePaths.configuration}>
                      <ConfigurationEditPage />
                    </PrivateRoute>
                    <Route exact path={pagePaths.home}>
                      <Homepage />
                    </Route>
                    <PrivateRoute
                      exact
                      path={[pagePaths.theses.my, pagePaths.theses.search]}
                      render={({ match }) => thesesList[match.path]}
                    />
                    <PrivateRoute exact path={pagePaths.theses.searchOfficer}>
                      <OfficerThesesListPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.create}>
                      <ThesisCreatePage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.edit()}>
                      <ThesisEditPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.view()}>
                      <ThesisViewPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.reviewerProposals()}>
                      <ThesisReviewerPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.reportCreate()}>
                      <ThesisReportCreatePage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.theses.reportView()}>
                      <ThesisReportViewPage />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path={Object.keys(topicSearchPages)}
                      render={({ match }) => topicSearchPages[match.path]}
                    />
                    <PrivateRoute exact path={pagePaths.topics.create}>
                      <TopicCreatePage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.topics.edit()}>
                      <TopicEditPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.topics.view()}>
                      <TopicViewPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.userSettings.root}>
                      <UserSettingsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={pagePaths.topics.createThesis()}>
                      <TopicThesisCreatePage />
                    </PrivateRoute>
                    <Route>
                      <NotFound />
                    </Route>
                  </Switch>
                </section>
              </main>
            </CollapseNavigationContainer.Provider>
          </ModalContainer>
        </ModalsContainer.Provider>
      </CurrentLanguageContainer.Provider>
    </UserInfoContainer.Provider>
  </Router>
)
export default App
