import { Role, ThesisRel } from '@cvut/profit-theses-common'
import { ThesisState } from '@cvut/profit-api-types/lib/theses'

import { LocalePanelId } from './ActionPanel'
import { ThesisPanelAction } from '../../api/theses'


type StateAndRelationToPanelIdMap = Partial<Record<ThesisState, Partial<Record<ThesisRel | Role, PanelDefinition>>>>

export interface PanelDefinition {
  panelId: LocalePanelId
  actions: ThesisPanelAction[]
}

export const mapStateAndRoleToPanelId: StateAndRelationToPanelIdMap = {
  'draft': {
    SUPERVISOR: {
      panelId: 'assignmentSupervisorProposal',
      actions: ['PROPOSE'],
    },
  },
  'proposed.awaitsSpecOfficerApproval': {
    SPEC_OFFICER: {
      panelId: 'assignmentSpecOfficerApproval',
      actions: ['OFFICER_APPROVE', 'REVISE'],
    },
    SUPERVISOR: {
      panelId: 'assignmentSupervisorWaitingForApproval',
      actions: ['REVISE', 'WITHDRAW'],
    },
  },
  'proposed.revision': {
    SUPERVISOR: {
      panelId: 'assignmentSupervisorRevision',
      actions: ['PROPOSE', 'WITHDRAW'],
    },
  },
  'proposed.awaitsAssigneeAcceptance': {
    ASSIGNEE: {
      panelId: 'assignmentAssigneeAcceptance',
      actions: ['ASSIGNEE_ACCEPT', 'ASSIGNEE_REJECT'],
    },
    SUPERVISOR: {
      panelId: 'assignmentAssigneeAcceptanceSupervisor',
      actions: ['WITHDRAW'],
    },
  },
  'proposed.rejectedByAssignee': {
    ASSIGNEE: {
      panelId: 'assignmentAssigneeSecondAcceptance',
      actions: ['ASSIGNEE_ACCEPT'],
    },
    SUPERVISOR: {
      panelId: 'assignmentRejectedByStudent',
      actions: ['WITHDRAW'],
    },
  },
  'changeable.assigned.reviewer.pending': {
    SUPERVISOR: {
      panelId: 'thesisSupervisorReviewerProposal',
      actions: ['ASSIGN_REVIEWER'],
    },
    SPEC_OFFICER: {
      panelId: 'thesisSupervisorReviewerProposal',
      actions: ['ASSIGN_REVIEWER'],
    },
  },
  'changeable.review.supervisor.pending': {
    SUPERVISOR: {
      panelId: 'thesisSupervisorReport',
      actions: ['SUBMIT_SUPERVISOR_REPORT'],
    },
  },
  'changeable.review.reviewer.pending': {
    REVIEWER: {
      panelId: 'thesisReviewerReport',
      actions: ['SUBMIT_REVIEWER_REPORT'],
    },
  },
  'evaluated': {
    [Role.FT_OFFICER]: {
      panelId: 'thesisSupersession',
      actions: ['SUPERSEDE'],
    },
  },
}
